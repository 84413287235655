// security page
.security-page {
   .security-container {
      width: 100%;
      max-width: 800px;
      margin: auto;
   }
}

.card-general-style {
   background-color: #1c1839;
   border-radius: 8px;
   padding: 20px 24px;
}

.ps-md-0 {

   padding-left: 1rem !important;
   padding-right: 1rem !important;

   @media screen and (max-width: 767px) {
      margin-bottom: 1rem;;
      padding-left: 0rem !important;
      padding-right: 0rem !important;
   }
}
.pe-md-0 {
   padding-left: 1rem !important;
   padding-right: 1rem !important;

   @media screen and (max-width: 767px) {
      
      padding-left: 0rem !important;
      padding-right: 0rem !important;
   }
}
// personal data cards
.personal-data-card {
   @extend .card-general-style;
   margin-bottom: 1.5rem;
}

.profile-input-container  {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
   }
}

.profile-input, .password-input-container {
   width: 100%;
   max-width: 570px;

   @media screen and (max-width: 850px) {
      max-width: unset;
   }
}

.input-label {
   margin-right: 0.7rem;

   @media screen and (max-width: 850px) {
      margin-right: 0;
      margin-bottom: 0.5rem;
   }
}

// 2FA card
.two-fa-card {
   height: 100%;
   min-height: 212px;
   @extend .card-general-style;
}

.status-wrapper {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
}

.status-badge {
   width: 52px;
   height: 24px;
   border-radius: 12px;
   margin-right: 12px;

   @media screen and (max-width: 1200px) {
      width: 48px;
      height: 20px;
   }
   @media screen and (max-width: 991px) {
      width: 40px;
      height: 16px;
   }
}

.active-bg {
   background-color: #58ff89;
}
.disabled-bg {
   background-color: #ff3c64;
}

.active-status {
   color: #00fc4a;
}
.disabled-status {
   color: #ff0000;
}
.pending-status{
   color:#f1c40f
}
.accepted-status{
   color: #00fc4a;

}
.rejected-status{
   color: #ff0000;

}
.ban-status{
   color: #ff0000;

}

.Inactive-status{
   color:#f1c40f

}


.fa-setup-container {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;

   @media screen and (max-width: 700px) {
      flex-direction: column;
      align-items: flex-start ;

     .qr-input {
         margin: 3rem 0;    
      }
   }
}

.qr-container {
   width: 150px;
   height: 150px;
   background-color: #ffffff;
}

.qr-input {
   width: 100%;
   max-width: 300px;
   height: 28px;
   color: #ffffff;
   outline: none;
   border: 2px solid #09eab4;
   background-color: transparent;
   border-radius: 28px;
   padding: 0.5rem 0.8rem;
}

.fa-button {
   width: 100%;
   max-width: 125px;
   height: 28px;
   border-radius: 28px;
   border: none;
   outline: none;
}

.activate-2fa {
   background-color: #ffea00;
}
.deactivate-bg {
   background-color: #ff3c64;
}