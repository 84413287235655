.white-color {
   color: #ffffff !important;
}
.lightgrey-color {
   color: #d1d1d1;
}
.darkgrey-color {
   color: #86868b;
}
.black-color {
   color: #000000;
}
.blue-color {
   color: #0FBCF9;
}
.purple-color {
   color: #af4aff;
}
.lightgreen-color {
   color: #00e0b8;
}
.pink-text {
   color: #af4aff;
}

.grey-text {
   color: #A0A0A0;
}

.yellow-text {
   color: #fda544;
}

.green-color-text {
   color: #00fc4a;
}
.red-color-text {
   color: #ff3c64;
}

.pinkGradientText {
   background-color: #22aaff;
   background-image: linear-gradient(to right, #22aaff, #8571db, #e23bb9);
   background-size: 70%;
   background-repeat: repeat;
   // background-clip: text;

   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   -moz-background-clip: text;
   -moz-text-fill-color: transparent;
}