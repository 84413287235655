.individual-pool {
  .green-color {
    color: #00fc4a;
  }
  .yellow-color {
    color: #ffea00;
  }
  .blue-color {
    color: #0fbcf9;
  }
  .red-color {
    color: #ff0000;
  }
  .yellow-switch::after {
    background: yellow !important;
  }
  .add-miner {
    border: 1px solid #1c2775;
    border-radius: 50px;
    padding: 0.3rem 0.75rem;
    color: #ffffff;
    cursor: pointer;

    & > svg {
      width: 20px;
    }
  }

  // switch toggle
  .toggle-switch-container {
    input[type="checkbox"] {
      height: 0;
      width: 0;
      display: none;
    }

    label {
      cursor: pointer;
      text-indent: -9999px;
      width: 30px;
      height: 15px;
      background: #070713;
      display: block;
      border-radius: 40px;
      position: relative;
    }

    label:after {
      content: "";
      position: absolute;
      top: 0px;
      left: 1px;
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 20px;
      transition: 0.3s;
    }

    input:checked + label:after {
      background: #00fc4a;
    }

    input:checked + label:after {
      left: calc(100% - 1px);
      transform: translateX(-100%);
    }

    label:active:after {
      width: 15px;
    }
  }
}

.pools-overview-first-row {
  @media screen and (min-width: 1200px) and (max-width: 1650px) {
    .card-title {
      font-size: 16px;
    }

    .card-label {
      font-size: 25px;
    }

    .supply-icon {
      width: 60px;
    }

    .info-icon {
      width: 15px;
    }
  }
}
