.activated-card {
  width: 100%;
  max-width: 600px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border: 1px solid #4ec44b;
  background-color: #0c220c;
  padding: 40px;
  margin: auto;

  .title {
    color: #4ec44b;
    margin-bottom: 3rem;
  }

  .success-icon {
    width: 120px;
    height: auto;
  }
}
