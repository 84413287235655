.user-dropdown {
   @media screen and (max-width: 991px) {
      display: none;
   }

   .dropdown-toggle::after {
      display: none;
   }

   .btn-success {
      background-color: transparent;
      border: none;

      &:hover, &:focus {
         background-color: transparent;
      }

   }

   .dropdown-toggle {
      background-color: transparent !important;
      border: none !important;
      box-shadow: none !important;
   }

   .dropdown-menu {
      background-color: #1c1839;
   }

   .dropdown-item {
      color: #ffffff;
      padding:  1rem;

      &:hover {
         background-color: #0e247f;
         color: #ffffff;
      }
   }
}