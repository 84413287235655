// @import '../../Styles/colors';
// GLOBAL COLORS
$white-color: #ffffff;

.white-color {
  color: $white-color;
}

$primary-white: #f5f5f5;

.color-main-white {
  color: $primary-white;
}

$main-blue: #023aff;

.color-main-blue {
  color: $main-blue;
}

$main-purple: #6c17ff;

.color-main-purple {
  color: $main-purple;
}

$dark-gray: #141414;

.color-dark-gray {
  color: $dark-gray;
}

$medium-gray: #282828;

.color-medium-gray {
  color: $medium-gray;
}

$light-gray: #9d9d9d;

.color-light-gray {
  color: $light-gray !important;
}

$main-red: #ff220c;

.color-main-red {
  color: $main-red;
}

$light-red: #ff2d2e;

.color-light-red {
  color: $light-red;
}

$magenta-color: #991bfa;
.magenta-color {
  color: $magenta-color;
}

$gold: #ffda3f;

.color-gold {
  color: $gold;
}

$yellow: #ffe13f;

.color-yellow {
  color: $yellow;
}

$light-orange: #ffa63f;

.color-light-orange {
  color: $light-orange;
}

$light-pink: #bb93ff;

.color-light-pink {
  color: $light-pink;
}

$light-teal: #01f1e3;

.color-light-teal {
  color: $light-teal;
}

$light-green: #5eff5a;

.color-light-green {
  color: $light-green;
}

$main-green: #4ec44b;

.color-main-green {
  color: $main-green;
}

$normal-gray: #434355;

$use-card-blue: #0c49ac;
$send-card-green: #0a6926;
$destroy-card-red: #4d1722;

.kyc-page {
  .custom-height-kyc {
    height: 450px;
    cursor: pointer;
  }

  .font-size-20 {
    font-size: 20px !important;

    @media screen and (max-width: 991px) {
      font-size: 20px !important;
    }
    @media screen and (max-width: 375px) {
      font-size: 18px !important;
    }
  }
  .font-size-24 {
    font-size: 24px !important;

    @media screen and (max-width: 991px) {
      font-size: 22px !important;
    }
    @media screen and (max-width: 375px) {
      font-size: 20px !important;
    }
  }

  .font-size-16 {
    font-size: 16px !important;

    @media screen and (max-width: 991px) {
      font-size: 15px !important;
    }
    @media screen and (max-width: 767px) {
      font-size: 14px !important;
    }
    @media screen and (max-width: 375px) {
      font-size: 13px !important;
    }
  }

  .font-size-14 {
    font-size: 14px !important;

    @media screen and (max-width: 767px) {
      font-size: 13px !important;
    }
  }

  .content-container-col {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gap-5 {
    @media screen and (max-width: 1433px) {
      gap: 1rem !important;
    }
  }

  .kyc-container {
    // height: 400px;
    width: 100%;
    max-width: 500px;
    // margin: auto;
    font-weight: 900 !important;
    background-color: $dark-gray;
    border-radius: 16px;
    color: #ffffff;

    @media screen and (max-width: 1433px) {
      max-width: 480px;
    }
    @media screen and (max-width: 1133px) {
      max-width: 420px;
    }
    @media screen and (max-width: 1010px) {
      max-width: 405px;
    }
    @media screen and (max-width: 991px) {
      max-width: 500px;
    }

    .primary-btn-kyc {
      max-width: 150px !important;
      padding: 0.5rem 0 !important;
      background-color: $main-blue !important;
      border-color: $main-blue !important;
      box-shadow: none !important;

      &:hover {
        background-color: $light-orange !important;
        color: #000 !important;
        border-color: $light-orange !important;
      }
    }

    .outline-btn-edain {
      max-width: 150px !important;
      padding: 0.5rem 0 !important;
    }
  }

  @media screen and (max-width: 767px) {
    .kyc-page {
      .custom-margin-mobile {
        margin-bottom: 1rem;
      }
    }
  }

  @media screen and (max-width: 820px) {
    .kyc-page {
      .custom-height-kyc {
        height: 500px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .kyc-page {
      .custom-height-kyc {
        height: 450px;
        padding: 0.2rem !important;
      }
      .font-size-20 {
        text-align: center !important;
        margin-top: 10px;
      }

      ul {
        padding-left: 30px;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .kyc-page {
      .outline-btn-edain,
      .primary-btn-kyc {
        padding: 0.25rem !important;
        width: 90px !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .kyc-page-container {
      flex-direction: column;
      align-items: center;
      gap: 0 !important;

      .personal-documents-guide {
        ul {
          padding-left: 10px;
        }
      }
    }
  }
}

.uploader {
  background-color: transparent;
  color: white;
  padding: 0;
  font-size: 24px;
  border: none;

  svg {
      width: 32px;
      height:auto
      
  }
}
.add-doc-input{
  border: 1px solid white;
    padding: 0.4rem;
    border-radius: 5px;
    border-style: dashed;
}