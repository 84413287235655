.navigation {
   background-color: #1c1839;
   border-radius: 8px;

   .logo {
      width: 200px;
   }

   .route-link, .logout-link {
      text-decoration: none;
      margin-right: 1rem;
      padding-right: 0.5rem;
      padding-left: 0.5rem;

      @media screen and (max-width: 991px) {
         padding: 1rem 0;
      }
   }

   .active-navbar-brand {
      #Path_40278 {
         fill: #0fbcf9;
      }
   }

   .route-link.active-navigation {
      position: relative;

      div {
         svg {
            path,
            rect {
               fill: #0fbcf9;
            }
         }
         p {
            color: #0fbcf9 !important;
         }
      }

      .highlight-underline {
         @media screen and (min-width: 992px) {
            position: absolute;
            width: calc(100% - 0.5rem);
            background-color: #0fbcf9;
            height: 3px;
            bottom: -16px;
            border-radius: 4px;
         }
      }
   }

   .logout-link.active {
      position: relative;

      div {
         svg {
            path,
            rect {
               fill: #ffffff;
            }
         }
         p {
            color: #ffffff !important;
         }
      }
   }

   .container {
      max-width: 1800px;
   }

   .toggle {
      border: none;

      & > span {
         width: 22px;
         background-image: url("../../Assets/SVGs/burger-menu.svg");
      }

      &:focus {
         box-shadow: none;
      }
   }

   .mobile-links {
      @media screen and (min-width: 992px) {
         display: none;
      }
   }

   .link-icon {
      color: #ffffff;
      font-size: 1.3rem;
   }
}

@media screen and (min-width: 992px) {
   .navigation {
      #basic-navbar-nav {
         display: flex;
         justify-content: center;
      }
   }
}

@media screen and (max-width: 991px) {
   .navigation {
      .avatar-container {
         display: none !important;
      }
   }
}

@media screen and (max-width: 350px) {
   .navigation {
      .toggle {
         & > span {
            width: 16px;
         }
      }
      .logo {
         width: 160px;
      }
   }
}

@media screen and (max-width: 290px) {
   .navigation {
      .toggle {
         & > span {
            width: 14px;
         }
      }
      .logo {
         width: 140px;
      }
   }
}
