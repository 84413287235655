.toggle-container {
   position: relative;
   width: 430px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: space-around;
   background-color: transparent;
   border: 2px solid #22aaff;
   border-radius: 48px;

   @media screen and (max-width: 767px) {
      height: 25px;
      font-size: 12px;
   }
   @media screen and (max-width: 576px) {
      // margin-top: 0.5rem;
      margin: 15px auto auto;
      width: 300px;
   }

   &.only-7days {
      width: 350px;

      .dayActive {
         left: 3px;
      }
      .weekActive {
         left: 135px;
      }
      .monthActive {
         left: 283px;
      }
   }
}

.activeButton {
   position: absolute;
   left: 3px;
   // left: 50%;
   // transform: translateX(-50%);
   height: 80%;
   width: 120px;
   display: flex;
   align-items: center;
   justify-content: space-around;
   background-color: #22aaff;
   color: #000000;
   font-size: 15px;
   border-radius: 48px;
   transition: all 0.2s ease-in-out;

   @media screen and (max-width: 767px) {
      font-size: 12px;
   }
   @media screen and (max-width: 576px) {
      width: 80px;
   }
}

.hourActive {
   left: 3px;
   // transform: unset;
}

.weekActive {
   left: 240px;
}

.dayActive {
   // left: 72px;
   left: 122px;
   // left: 50%;
   // transform: translateX(-50%);
}

.monthActive {
   // left: 228px;
   left: 364px;
   width: 60px;
   // left: auto;
   // transform: translateX(100%);
}

@media screen and (max-width: 576px) {
   .toggle-container.only-7days {
      width: 300px;
   }
   .toggle-container.only-7days .weekActive {
      left: 124px;
   }
   .toggle-container.only-7days .monthActive {
      left: 234px;
   }
   .toggle-container.simple .dayActive {
      left: 90px;
   }
   .toggle-container.simple .weekActive {
      left: 170px;
   }
   .toggle-container.simple .monthActive {
      left: 254px;
      width: 40px;
   }
}