.card-icons {
   margin-bottom: 12px;
}



@media screen and (min-width: 992px) {
   .statistics-grid {
      .fourth-row {
         .font-size-36 {
            font-size: 20px;
         }
      }
   }
}

