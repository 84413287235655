.activate-pool {
   min-height: 100vh;

   .word-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #423e5f;
      color: #d5d5d5;
      border-radius: 8px;
      margin: 7px;
      padding: 0.5rem 0.7rem;
   }

   .inner-wrapper {
      width: 100%;
      max-width: 470px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
   }

   .tx-id {
      width: 100%;
      max-width: 600px;
   }

   .copy-to-clipboard {
      border-radius: 16px;
      border: 1.5px solid #ffffff;
      width: 100px;
      height: 26px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
   }
}
