.send-email,
.change-password {
   height: calc(100vh - 122.16px);

   .main-box {
      max-width: 400px;
      background-image: url("../../../Assets/PNGs/big-sphere.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .buttonOutlineBlue {
         height: 36px;
      }

      .psw-icon {
         width: 16px;
      }
   }
}
