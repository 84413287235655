.add-miner-page {
   min-height: 100vh;

   .word-tag {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #423e5f;
      color: #d5d5d5;
      border-radius: 8px;
      margin: 7px;
      padding: 0.5rem 0.7rem;
   }
}