.pools-overview-first-row {
   @media screen and (min-width: 1200px) and (max-width: 1650px) {
      .card-title {
         font-size: 16px;
      }
   
      .card-label {
         font-size: 25px;
      }

      .supply-icon {
         width: 60px;
      }

      .info-icon {
         width: 15px;
      }
   }

}