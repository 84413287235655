.filter-container {
  .my-pools-search {
     @media screen and (max-width: 767px) {
        margin-bottom: 1.5rem;
     }
  }

   .filter-button {
      width: 100%;
      max-width: 160px;
      height: 36px;
      outline: none;
      border: none;
      border: 1px solid #0a4f6e;
      color: #6a6a71;
      font-size: 16px;
      background-color: transparent;
      border-radius: 20px;

      &:not(:last-child) {
         margin-right: 24px;

         @media screen and (max-width: 575px) {
            margin-right: 16px;
         }
         @media screen and (max-width: 350px) {
            margin-right: 10px;
         }
      }

      @media screen and (max-width: 991px) {
         font-size: 14px;
      }
      @media screen and (max-width: 575px) {
         font-size: 12px;
         height: 28px;
      }
   }

   .active-button {
      @extend .filter-button;
      background-color: #0fbcf9;
      color: #070713;
   }

   .pools-span {
      @media screen and (max-width: 350px) {
         display: none;
      }
   }
}