.sign-in-page {
   display: flex;
   justify-content: center;
   align-items: center;
   height: calc(100vh - 122.16px);

   .reset-psw {
      font-size: 0.7rem;
      color: #22aaff;
      cursor: pointer;
   }

   .key-icon {
      color: #22aaff;
   }

   .main-box {
      max-width: 400px;
      background-image: url("../../../Assets/PNGs/big-sphere.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;

      .buttonOutlineBlue {
         height: 36px;
      }
   }
}
