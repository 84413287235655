.step-card {
   .inner-wrapper {
      width: 100%;
      max-width: 470px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: auto;
   }

   .device-icon {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .devices-container {
      width: 71px;
   }

   .device-label {
      margin-top: 5px;
   }

   .inactive-type {
      background-color: #0F0B2C;
      border: 1px solid #0F0B2C;
   }

   .active-type {
      background-color: #061753;
      border: 1px solid #0fbcf9;
   }
}
