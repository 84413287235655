.btn-primary-radius {
   background-color: #22aaff !important;
   border-radius: 20px !important ;
   border: none;
   font-family: proxima-nova, sans-serif;
   font-weight: 400;
   font-style: normal;
   width: 160px;
   height: 36px;
   color: #000000;

   &:focus {
      box-shadow: none !important;
   }
}
.btn-outline-primary-radius {
   border-radius: 20px !important ;
   border-color: #22aaff !important ;
   font-family: proxima-nova, sans-serif;
   font-weight: 400;
   font-style: normal;
   width: 160px;
   height: 36px;
   color: #ffffff !important;
   background-color: transparent;

   &:focus {
      box-shadow: none !important;
   }

   &:hover {
      background-color: transparent !important;
   }
}

.black-color.btn-primary {
   color: black !important;
}

.buttonBlue {
   width: 100%;
   max-width: 160px;
   height: 48px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #22aaff;
   border-radius: 8px;
   border: none;
   outline: none;
   font-family: proxima-nova, sans-serif;
   font-size: 20px;
   color: #000000;
   text-decoration: none;
   cursor: pointer;
   margin-right: 1rem;

   &:hover {
      background-color: #81c9f7;
      color: #000000;
   }
}

.buttonOutlineBlue {
   width: 100%;
   max-width: 160px;
   height: 48px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: transparent;
   border: 2px solid #22aaff;
   border-radius: 8px;
   font-family: proxima-nova, sans-serif;
   font-size: 20px;
   color: #22aaff;
   text-decoration: none;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   margin-right: 1rem;

   &:hover {
      background-color: #22aaff;
      color: #000000;
   }
}

.buttonYellow {
   background-color: #ffea00 !important;
   border-radius: 16px !important ;
   border: none;
   font-family: proxima-nova, sans-serif;
   font-weight: 400;
   font-style: normal;
   width: 100%;
   max-width: 246px;
   height: 36px;
   color: #000000;

   &:focus {
      box-shadow: none !important;
   }
}

.buttonRed {
   width: 100%;
   max-width: 160px;
   height: 48px;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #4d1722;
   border: 2px solid #4d1722;
   border-radius: 8px;
   font-family: proxima-nova, sans-serif;
   font-size: 20px;
   color: #ff3c64;
   text-decoration: none;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   margin-right: 1rem;
}
