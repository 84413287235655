@import "./buttons.scss";
@import "./container.scss";
@import "./fonts.scss";
@import "./color.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  min-height: 100vh;
  background-color: #070713;
  font-family: proxima-nova, sans-serif;
}

// cursor-pointer --------------------------------------------
.cursor-pointer {
  cursor: pointer;
}

// page height---------------------------------------------------
.page-height {
  height: calc(100vh - 122.16px);
}
.min-page-height {
  min-height: calc(100vh - 122.16px);
}

// boxes -----------------------------------------------------
.main-box {
  width: 100%;
  background-color: #1c1839;
  border-radius: 8px;
  padding: 20px 24px;

  @media screen and (max-width: 450px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.main-active-box {
  width: 100%;
  background-color: #1d4aff;
  border-radius: 8px;
  padding: 20px 24px;
}

.height-252 {
  height: 282px;
}

.height-114 {
  height: 128px;
}

// chevron icon  -----------------------------------------------------
.chevron-icon {
  margin-top: 0.2rem;
  transition: all 0.3s;
}

.hide {
  transform: rotate(180deg);
}

// table -----------------------------------------------------
.table {
  thead {
    th {
      background-color: #1c2775;
      padding: 1rem;
      white-space: nowrap;
    }

    th:first-child {
      border-radius: 8px 0 0 0;
    }

    th:last-child {
      border-radius: 0 8px 0 0;
    }

    tr:last-child {
      border-radius: 0 0 0 0;
    }
  }

  tbody {
    td {
      padding: 1rem;
      white-space: nowrap;
      .xden-icon {
        width: 30px;
        height: 30px;
      }
    }
  }

  tbody {
    tr:hover {
      cursor: pointer;
      td {
        color: #5b67c0;
      }
    }
  }

  tr:nth-child(even) {
    background-color: #1c1839;
  }

  tr:nth-child(odd) {
    background-color: #16132e;
  }

  tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }

  tbody,
  td,
  tfoot,
  th,
  thead,
  tr {
    border: none !important;
    color: #ffffff;
  }

  .row-link,
  .row-link:hover {
    color: #0fbcf9;
  }
}

.inherit {
  height: inherit;
}

// input -----------------------------------------------------
.pool-input {
  height: 48px;
  padding: 0 1rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #16132e;
  color: #ffffff;
}

// search-input -----------------------------------------------------
.search-input {
  border: none;
  border-bottom: 1px solid #1890ff;
  padding: 5px 10px;
  outline: none;
  background: transparent;
  color: #ffffff;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease;
  text-indent: -100%;
  opacity: 1;
}

// status-badges -----------------------------------------------------

.transaction-status {
  background-color: #0f2580;
  padding: 0.2rem 1.5rem;
  border-radius: 11px;
}

.purple-status {
  background-color: #430066;
  padding: 0.2rem 1.5rem;
  border-radius: 11px;
}

.private-status {
  background-color: #084e1c;
  color: #00fc4a;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90px;
  padding: 0 1rem;
  border-radius: 11px;
}

.public-status {
  background-color: #762d14;
  color: #ff8157;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 90px;
  padding: 0 1rem;
  border-radius: 11px;
}

// Tooltips----------------------------------------------------------------
.tooltip-inner {
  background-color: #1b2674 !important;
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.bs-tooltip-top .tooltip-arrow::before {
  border-top-color: #1b2674 !important;
}

// Charts legend -------------------------------------------------------
.chart-legend {
  position: absolute;
  right: 24px;
  bottom: 20px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 400px) {
    display: none;
  }
}

.card-icons {
  margin-bottom: 12px;
}

.legendTooltip {
  position: absolute;
  top: 10px;
  right: 10px;

  @media screen and (min-width: 401px) {
    display: none;
  }
}

// hide password icon---------------------------------------------------------
.hide-pwd-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #15122d;
}

// toastify-----------------------------------------------------------------
.Toastify__toast-theme--light {
  background-color: #1d4aff !important;
  color: #ffffff !important;
  font-family: proxima-nova, sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

// Modal ---------------------------------------------------------
.modal-content {
  background-color: #1c1839 !important;
}

.modal-body {
  padding: 3rem 2rem !important;
}

.modal-button-blue {
  width: 100%;
  max-width: 240px;
  height: 48px;
  background-color: #0fbcf9;
  color: #000000;
  border: none;
  outline: none;
  border-radius: 28px;

  @media screen and (max-width: 767px) {
    height: 40px;
  }
}

.modal-button-outline {
  width: 100%;
  max-width: 240px;
  height: 48px;
  background-color: transparent;
  color: #0fbcf9;
  border: none;
  border: 1px solid #0fbcf9;
  outline: none;
  border-radius: 28px;

  @media screen and (max-width: 767px) {
    height: 40px;
  }
}

// Setup pages ---------------------------------------------------
.setup-card {
  width: 100%;
  max-width: 800px;
  height: auto;
  background-color: #1c1839;
  border-radius: 8px;
  padding: 20px 24px;
}

.step-tag {
  width: 100%;
  max-width: 156px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background-color: #09eab4;
  color: #000000;

  @media screen and (max-width: 575px) {
    max-width: 120px;
    height: 28px;
  }
}

.space-bottom {
  margin-bottom: 1.5rem;
}

.setup-buttons-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .setup-outline-btn {
    width: 100%;
    max-width: 250px;
    height: 48px;
    border: none;
    outline: none;
    background-color: transparent;
    border: 1px solid #0fbcf9;
    border-radius: 8px;
    color: #0fbcf9;
    margin-right: 1.5rem;

    @media screen and (max-width: 767px) {
      height: 40px;
    }
  }

  .setup-fill-btn {
    width: 100%;
    text-decoration: none;
    max-width: 250px;
    height: 48px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: #0fbcf9;
    color: #000000;
line-height: 48px;
    @media screen and (max-width: 767px) {
      height: 40px;
      line-height: 40px;

    }

    &:disabled,
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

// coming soon blur overlay=====================================================================
.blur-overlay > *:not(.coming-soon-span) {
  // background: #000000;
  background: #1b1738;
  filter: blur(10px);
  pointer-events: none;

  @supports not (backdrop-filter: blur(10px)) {
    & > *:not(.coming-soon-span) {
      filter: blur(4px);
    }
  }
}

.coming-soon-span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  color: #86868b;
  z-index: 10;

  // <span className="font-size-40 font-pnb coming-soon-span">Coming soon</span>
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}

.apexcharts-xaxistooltip-bottom {
  display: none !important;
}

// apex chart font family====================================================
.apexcharts-text.apexcharts-datalabel-label {
  font-family: proxima-nova, sans-serif !important;
}
