//TODO import fonts - Cosmin

.sm-line-height {
   line-height: 1.2;
}

.font-pnb {
   font-family: proxima-nova, sans-serif;
   font-weight: 700;
   font-style: normal;
   @extend .sm-line-height;
}

.font-pnr {
   font-family: proxima-nova, sans-serif;
   font-weight: 400;
   font-style: normal;
   @extend .sm-line-height;
}

.font-pnsb {
   font-family: proxima-nova, sans-serif;
   font-weight: 600;
   font-style: normal;
   @extend .sm-line-height;
}

.font-pnl {
   font-family: proxima-nova, sans-serif;
   @extend .sm-line-height;
}

.font-pnb {
   font-family: proxima-nova, sans-serif;
   font-weight: 700;
   font-style: normal;
   @extend .sm-line-height;
}

.font-pnr {
   font-family: proxima-nova, sans-serif;
   font-weight: 400;
   font-style: normal;
   @extend .sm-line-height;
}

.font-pnsb {
   font-family: proxima-nova, sans-serif;
   font-weight: 600;
   font-style: normal;
   @extend .sm-line-height;
}

.font-pnl {
   font-family: proxima-nova, sans-serif;
   font-weight: 300;
   font-style: normal;
   @extend .sm-line-height;
}

.font-size-80 {
   font-size: 80px;

   @media screen and (max-width: 1400px) {
      font-size: 70px;
   }
   @media screen and (max-width: 1200px) {
      font-size: 60px;
   }
   @media screen and (max-width: 1100px) {
      font-size: 50px;
   }
   @media screen and (max-width: 991px) {
      font-size: 40px !important;
   }
   @media screen and (max-width: 767px) {
      font-size: 32px !important;
   }
}

.font-size-64 {
   font-size: 64px;
   @media screen and (max-width: 1200px) {
      font-size: 50px !important;
   }
   @media screen and (max-width: 1089px) {
      font-size: 35px !important;
   }
   @media screen and (max-width: 767px) {
      font-size: 30px !important;
   }
   @media screen and (max-width: 600px) {
      font-size: 25px !important;
   }
}

.font-size-54 {
   font-size: 54px;

   @media screen and (max-width: 1200px) {
      font-size: 40px !important;
   }
   @media screen and (max-width: 767px) {
      font-size: 30px !important;
   }
}

.font-size-56 {
   font-size: 56px;
   @media screen and (max-width: 1400px) {
      font-size: 50px !important;
   }
   @media screen and (max-width: 1200px) {
      font-size: 40px !important;
   }
   @media screen and (max-width: 991px) {
      font-size: 35px !important;
   }
   @media screen and (max-width: 767px) {
      font-size: 30px !important;
   }
   @media screen and (max-width: 600px) {
      font-size: 25px !important;
   }
}

.font-size-48 {
   font-size: 48px;

   @media screen and (max-width: 1430px) {
      font-size: 40px;
   }
   @media screen and (max-width: 1200px) {
      font-size: 35px;
   }
   @media screen and (max-width: 767px) {
      font-size: 30px;
   }
   @media screen and (max-width: 600px) {
      font-size: 25px;
   }
}

.font-size-40 {
   font-size: 40px;

   @media screen and (max-width: 1430px) {
      font-size: 35px;
   }
   @media screen and (max-width: 1200px) {
      font-size: 28px;
   }
   @media screen and (max-width: 767px) {
      font-size: 26px;
   }
   @media screen and (max-width: 600px) {
      font-size: 22px;
   }
}

.font-size-36 {
   font-size: 36px;
   @media screen and (max-width: 1200px) {
      font-size: 30px;
   }
   @media screen and (max-width: 991px) {
      font-size: 24px;
   }
   @media screen and (max-width: 600px) {
      font-size: 22px;
   }
}

.font-size-32 {
   font-size: 32px;
   @media screen and (max-width: 1200px) {
      font-size: 28px;
   }
   @media screen and (max-width: 991px) {
      font-size: 24px;
   }
   @media screen and (max-width: 600px) {
      font-size: 22px;
   }
}

.font-size-28 {
   font-size: 28px;
   @media screen and (max-width: 1200px) {
      font-size: 24px;
   }
   @media screen and (max-width: 991px) {
      font-size: 28px;
   }
   @media screen and (max-width: 600px) {
      font-size: 16px;
   }
}

.font-size-24 {
   font-size: 24px;
   @media screen and (max-width: 1200px) {
      font-size: 22px;
   }
   @media screen and (max-width: 991px) {
      font-size: 20px;
   }
   @media screen and (max-width: 600px) {
      font-size: 18px;
   }
}

.font-size-23 {
   font-size: 23px;
   @media screen and (max-width: 1200px) {
      font-size: 20px;
   }
   @media screen and (max-width: 991px) {
      font-size: 18px;
   }
   @media screen and (max-width: 767px) {
      font-size: 16px;
   }
}

.font-size-20 {
   font-size: 20px;
   @media screen and (max-width: 1200px) {
      font-size: 16px;
   }
   // @media screen and (max-width: 991px) {
   //    font-size: 16px;
   // }
   @media screen and (max-width: 600px) {
      font-size: 14px;
   }
   @media screen and (max-width: 400px) {
      font-size: 12px;
   }
}

.font-size-18 {
   font-size: 18px;
   @media screen and (max-width: 1400px) {
      font-size: 17px;
   }
   @media screen and (max-width: 1200px) {
      font-size: 16px;
   }
   @media screen and (max-width: 767px) {
      font-size: 14px;
   }
   @media screen and (max-width: 600px) {
      font-size: 12px;
   }
}

.font-size-16 {
   font-size: 16px !important;
}

.font-size-15 {
   font-size: 15px;
}
