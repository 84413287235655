.add-router-page {

   .form-card {
      width: 100%;
      max-width: 800px;
      // min-height: 300px;
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 20px 24px;
      margin: auto;
      background-color: #1b1738;
      border-radius: 8px;

      @media screen and (max-width: 350px) {
         padding: 1rem;
      }
   }
}