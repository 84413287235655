.economics {
   .first-row {
      .transaction {
         background-color: #16132e;
         border-radius: 8px;
         margin-bottom: 12px;
         padding: 8px 20px;
      }
   }
}

.hash-label {
   width: 180px;
}

.transaction-hash, .transaction-value {
   @media screen and (max-width: 600px) {
      display: none !important;
   }
}

.status {
   @media screen and (max-width: 350px) {
      font-size: 12px !important;
      padding: 0.2rem 0.7rem;
   }
}

@media screen and (min-width: 992px) and (max-width: 1575px) {
   .first-row {
      .font-size-40 {
         font-size: 30px;
      }
      .font-size-36 {
         font-size: 26px;
      }
   }
}

.economics-second-row {
   @media screen and (min-width: 992px) and (max-width: 1550px) {
      .font-size-20 {
         font-size: 14px !important;
      }
      .font-size-32 {
         font-size: 24px !important;
      }
      .info-icons {
         width: 18px;
      }
   }
}

@media screen and (max-width: 576px) {
   .scanner {
      .transaction {
         padding: 2px !important;

         & > p:first-child {
            display: none;
         } 

         & > p {
            width: 49% !important;
            padding: 0.1rem !important;
            text-align: center !important;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
         }
      }
   }

}