.error-page {
   height: 100vh;

   .error-image {
      width: 100%;
      max-width: 600px;
      height: auto;
   }

   .backButton {
      width: 100% !important;
      max-width: 230px !important;
      margin: auto;
   }
}