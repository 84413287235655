// GLOBAL COLORS
$white-color: #ffffff;

.white-color {
  color: $white-color;
}

$primary-white: #f5f5f5;

.color-main-white {
  color: $primary-white;
}

$main-blue: #023aff;

.color-main-blue {
  color: $main-blue;
}

$dark-blue: #00a7ff;

.color-dark-blue {
  color: $dark-blue;
}

$main-purple: #6c17ff;

.color-main-purple {
  color: $main-purple;
}

$dark-gray: #141414;

.color-dark-gray {
  color: $dark-gray;
}

$medium-gray: #282828;

.color-medium-gray {
  color: $medium-gray;
}

$light-gray: #9d9d9d;

.color-light-gray {
  color: $light-gray !important;
}

$main-red: #ff220c;

.color-main-red {
  color: $main-red;
}

$light-red: #ff2d2e;

.color-light-red {
  color: $light-red;
}

$magenta-color: #991bfa;
.magenta-color {
  color: $magenta-color;
}

$gold: #ffda3f;

.color-gold {
  color: $gold;
}

$yellow: #ffe13f;

.color-yellow {
  color: $yellow;
}

$light-orange: #ffa63f;

.color-light-orange {
  color: $light-orange;
}

$light-pink: #bb93ff;

.color-light-pink {
  color: $light-pink;
}

$light-teal: #01f1e3;

.color-light-teal {
  color: $light-teal;
}

$light-green: #5eff5a;

.color-light-green {
  color: $light-green;
}

$main-green: #4ec44b;

.color-main-green {
  color: $main-green;
}

$normal-gray: #434355;

$use-card-blue: #0c49ac;
$send-card-green: #0a6926;
$destroy-card-red: #4d1722;

.steps-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;

  .steps-axis {
    width: 100%;
    height: 1px;
    background-color: #1f1f1f;
    z-index: 10;
  }

  .step {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border: 2px solid #656565;
    border-radius: 50%;
    background-color: #000000;
    z-index: 20;
  }

  .step-1 {
    left: 0;
  }
  .step-2 {
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .step-3 {
    right: 0;
  }

  .step-active {
    @extend .step;
    background: #000000 url("../../Assets/Icons/success.png") no-repeat center;
    background-size: contain;
    border: none;
    border-radius: unset;
    overflow: hidden;
  }
}

// ========================================= General style ============================================
// .enable-2fa-section {
//    width: 100%;
//    height: 100%;
//    display: flex;
//    flex-direction: column;
// }

// .status-badge {
//    width: 52px;
//    height: 24px;
//    border-radius: 12px;
//    margin-right: 1rem;

//    @media screen and (max-width: 767px) {
//       width: 40px;
//       height: 20px;
//    }
// }

// ======================================== Active status =============================================
.active-2fa {
  width: 100%;
  max-width: 800px;
  height: auto;
  padding: 24px;
  background-color: $dark-gray;
  margin: auto;

  .status-container {
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
  }

  // .status-badge-active {
  //    background-color: $light-green;
  //    @extend .status-badge;
  // }

  .disable-btn {
    width: 100%;
    max-width: 200px;
    border: none;
    outline: none;
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
    transition: all 0.2s ease-in-out;

    &:hover {
      border: none;
      background-color: $main-blue;
      color: #ffffff;
    }
  }
}

// ======================================== 2 FA Setup Main =============================================
// .status-badge-inactive {
//    background-color: $light-red;
//    @extend .status-badge;
// }

.main-card-2fa {
  width: 100%;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  padding: 35px;
  margin: auto;
  background-color: $dark-gray;

  @media screen and (max-width: 600px) {
    padding: 24px;
  }
}

.setup-cards-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  justify-content: space-between;
  margin: auto;

  @media screen and (max-width: 450px) {
    flex-direction: column;
  }
}

.setup-card {
  width: 100%;
  height: 400px;
  border-radius: 24px;
  background: $medium-gray;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 991px) {
    height: 380px;
  }
  @media screen and (max-width: 767px) {
    height: 300px;
  }
  @media screen and (max-width: 575px) {
    height: 250px;
  }
}

// .card-hover-effect:hover {
//    box-shadow: 0px 0px 7px #ffffff;
// }

.sms-card {
  margin-right: 12px;

  @media screen and (max-width: 450px) {
    margin-right: 0;
    margin-bottom: 24px;
  }
}

.app-card {
  margin-left: 12px;

  @media screen and (max-width: 450px) {
    margin-left: 0;
  }
}

.active-2fa-card {
  @extend .setup-card;
  background-color: #061753;
  border: 2px solid $main-blue;
}

.tfa-img {
  @media screen and (max-width: 991px) {
    width: 100px;
    height: auto;
  }
  @media screen and (max-width: 767px) {
    width: 80px;
    height: auto;
  }
  @media screen and (max-width: 575px) {
    width: 60px;
    height: auto;
  }
  @media screen and (max-width: 500px) {
    width: 40px;
    height: auto;
  }
}

.card-heading {
  @media screen and (max-width: 500px) {
    font-size: 20px !important;
  }
}

.control-2fa-btn {
  max-width: 248px;
  margin-top: 40px;
}

.skip-span {
  color: #e2e4f0;
}

//  ============================================= SMS & App setup ========================================
.set-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  align-items: center;
  margin: auto;

  @media screen and (max-width: 767px) {
    flex-direction: column;
    max-width: 400px;
  }
}

.single-setup-card {
  margin-right: 1.5rem;
  max-width: 400px;

  @media screen and (max-width: 767px) {
    margin-right: 0;
    margin-bottom: 1.5rem !important;
  }
}

.code-container {
  position: relative;
  height: 48px !important;
  background: $medium-gray;
  border-radius: 10px;
  width: 100%;

  .code-input {
    width: 100%;
    background: transparent !important;
    border: none;
    outline: none;
    color: #fff;
  }

  .verify-btn {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100px;
    background: $main-blue;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;

    @media screen and (max-width: 400px) {
      width: 80px;
    }
    @media screen and (max-width: 320px) {
      display: none !important;
    }
  }
}

.qr-container {
  @media screen and (max-width: 699px) {
    margin-top: 2rem;
  }

  .qr-code {
    @media screen and (max-width: 768px) {
      width: 150px;
      height: auto;
    }

    @media screen and (max-width: 699px) {
      padding: 2rem 0;
    }

    @media screen and (max-width: 400px) {
      width: 100px;
    }
  }
}

.qr-wrapper {
  background: #fff;
}

.process-btn {
  max-width: 150px;
  margin-top: 1.5rem;
  background-color: #4ec44b !important;
}

.copy-btn {
  width: 100%;
  max-width: 150px;
  border: none;
  outline: none;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
  margin-top: 1.5rem;
}

// ================================================ confirm secure code =========================================
.confirm-form {
  width: 100%;
  max-width: 500px;
  margin: auto;

  .process-btn-code {
    background-color: #4ec44b !important;
  }
}

#two-fa-section {
  h2,
  p,
  h1,
  h4 {
    color: white !important;
  }
}
.general-btn-style:disabled {
  color: rgba(255, 255, 255, 0.349) !important;
}

// BUTTONS
//======================================================== BUTTONS ==================================================
.zixxar-blue-button {
  background-color: $main-blue;
  @extend .general-btn-style;
}

.zixxar-blue-button {
  // from blue to purple background gradient with hover effect
  background: transparent;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 5;
  &:hover {
    transition: all 0.3s ease-in-out;
    color: white;
    background: linear-gradient(90deg, #8a2be2 0%, #0033ff 100%);
  }

  svg {
    z-index: 5;
    position: relative;
  }
}
.zixxar-blue-button {
  height: 36px !important;
  display: flex;
  justify-content: center;
  border: none;
  color: white;
  align-items: center;
  width: 180px !important;
  border-radius: 12px !important;
  cursor: pointer;

  @media screen and (max-width: 991px) {
    margin: auto !important;
    margin-top: 10px!important;
    width: 150px !important;
  }
}
.btn-height-48 {
  height: 48px;

  @media screen and (max-width: 767px) {
    height: 36px;
  }
}
.copy-btn {
  width: 100%;
  max-width: 150px;
  border: none;
  outline: none;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
  margin-top: 1.5rem;
}
.control-2fa-btn {
  background-color: #0033ff;
  max-width: 248px;
  margin-top: 40px;
}

.btn-height-56 {
  height: 56px;

  @media screen and (max-width: 1024px) {
    height: 50px;
  }

  @media screen and (max-width: 767px) {
    height: 46px;
  }

  @media screen and (max-width: 575px) {
    height: 38px;
  }
}
.general-input-style {
  width: 100%;
  border: none;
  outline: none;
}

.primary-input {
  border-radius: 12px;
  background-color: $medium-gray;
  color: #ffffff;
  padding: 12px 16px;
  @extend .general-input-style;
}
.radius-12 {
  border-radius: 12px;
}
