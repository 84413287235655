.chart-overlay {
   position: absolute;
   top: 0;
   // bottom: 0;
   left: 0;
   // right: 0;
   width: 100%;
   // height: 100%;
   display: flex;
   align-items: flex-start;
   // justify-content: space-between;
   padding: 20px 20px 0;

   .controls-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 400px) {
         flex-direction: column;
      }
   }
}
.apexcharts-toolbar {
   display: none !important;
}
