.mining-first-row {
   .card-icons {
      margin-bottom: 12px;
   }

   @media screen and (min-width: 992px) and (max-width: 1550px) {
      .font-size-20 {
         font-size: 12px !important;
      }
      .font-size-32 {
         font-size: 24px !important;
      }
      .font-size-36 {
         font-size: 22px !important;
      }

      .info-icons {
         width: 18px;
      }
   }

   @media screen and (min-width: 992px) and (max-width: 1170px) {
      .font-size-32 {
         font-size: 20px !important;
      }
   }
}

.mining-second-row {
   @media screen and (min-width: 992px) and (max-width: 1550px) {
      .font-size-20 {
         font-size: 12px !important;
      }
      .font-size-32 {
         font-size: 20px !important;
      }
      .info-icons {
         width: 18px;
      }
   }
}

