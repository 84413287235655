.mining-banner {   
    .mining-banner-img {
        width: 100%;
        max-width: 540px;
        height: auto;
    }   

    .mining-icon {
        width: 100%;
        max-width: 350px;
        height: auto;
    }
}
.yellow-color{
    color:#f9ca53;
    // font-weight:800;
}
.banner-announcement{
    border:rgb(249, 202, 83) ;
    box-shadow: 0 0 25px rgb(249 202 83 / 70%);
//background gradient
background: linear-gradient(90deg, rgba(4, 12, 48, 0.1) 0%, rgba(171, 177, 220, 0.1) 100%), #1c1839;
}
@media screen and (max-width: 767px) {
    .mining-banner { 
        .pool-col {
            align-items: center;
            text-align: center;            
        }

        .banner-col {
            justify-content: center;

            .mining-banner-img {
                max-width: 250px;
            }
        }
    }
}