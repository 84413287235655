.hero-box {
   position: relative;

   .hero-info-icon {
      position: absolute;
      right: 24px;
   }
}

@media screen and (max-width: 767px) {
   .hero-box {
      .pool-icon {
         width: 30px;
      }
   }
}

@media screen and (min-width: 768px) {
   .hero-box {
      .max-width-desktop {
         max-width: 900px;
         margin: 0 auto;
      }
   }
}

@media screen and (max-width: 450px) {
   .hero-box {
      .hero-info-icon {
         right: 1rem;
      }
   }
}
