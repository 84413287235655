.device-container-change-log, .date-container-change-log {
    border-radius: 32px;
    color: #000;
    height: 48px;
    width: 200px;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 768px) {
        width: 120px;
        font-size: 12px;
    }
    @media screen and (max-width: 576px) {
        width: 100%;
        font-size: 16px;
        height: 38px;
    }
}
.device-container-change-log {
    background: #bc6afd;
}
.date-container-change-log {
    background: #2af;
}
.change-log-updatetype {
    border-radius: 8px;
    color: #000;
    height: 32px;
    line-height: 32px;
    width: 234px;
}
.change-log-updatetype {
    background: #f9ca53;
}
.changelog-loadmore {
    color: #000;
    text-decoration: none;
    background: #0FBCF9;
    width: 160px;
    height: 48px;
    line-height: 48px;
    display: inline-block;
    text-align: center;
    border-radius: 8px;

    &:hover {
        background: #0a84b0;
        color: #000;
    }

    @media screen and (max-width: 576px) {
        width: 100%;
        height: 38px;
        line-height: 38px;
    }
}