.withdraw-page {
   min-height: 100vh;
   .withdraw-container {
      width: 100%;
      max-width: 800px;
      margin: auto;
      margin-bottom: 3vh;
   }
}

.page-height{
   height:auto;
}

.card-general-style {
   background-color: #1c1839;
   border-radius: 8px;
   padding: 20px 24px;
}

// main card ====================================
.main-card {
   @extend .card-general-style;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 1.5rem;
}

.info-wrapper {
   width: 100%;
   max-width: 500px;
}

// amount card =========================================
.amount-card {
   @extend .card-general-style;
   display: flex;
   flex-direction: column;
   margin-bottom: 1.5rem;
}

.gray-text {
   color: #d5d5d5;
}

// config card =====================================================
.config-card {
   @extend .card-general-style;
   display: flex;
   flex-direction: column;
}

.withdraw-input-container  {
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;

   @media screen and (max-width: 850px) {
      flex-direction: column;
      align-items: flex-start;
   }
}

.withdraw-input {
   width: 100%;
   max-width: 570px;

   @media screen and (max-width: 850px) {
      max-width: unset;
   }
}

.input-label {
   margin-right: 0.7rem;

   @media screen and (max-width: 850px) {
      margin-right: 0;
      margin-bottom: 0.5rem;
   }
}